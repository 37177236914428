
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: 'person',
      title: '個資',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "個資清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "個資清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: "Nickname", title: "暱稱", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "MobilePhone", title: "行動電話", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Sex", title: "性別", showHeaderOverflow: true, showOverflow: true, resizable: false, formatter: ({ cellValue }) => cellValue && model ? Object.values(model.enums.Sex).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "Gender", title: "性向", showHeaderOverflow: true, showOverflow: true, resizable: false, formatter: ({ cellValue }) => cellValue && model ? Object.values(model.enums.Gender).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => model.dispatch("person/query", params) : undefined,
        queryAll: model ? () => model.dispatch("person/query") : undefined,
        save: model ? (params) => model.dispatch("person/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'Nickname', title: '暱稱', span: 12, itemRender: { name: '$input', props: { placeholder: '暱稱' } } },
        { field: 'PersonalId', title: '身分證字號', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'Sex', title: '性別', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'BirthDate', title: '出生日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '請輸入文字' } } },
        { field: 'Gender', title: '性向', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇' }, options: model ? Object.values(model.enums.Gender).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'MobilePhone', title: '行動電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Phone', title: '市內電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Email', title: 'Email', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'Address.Line', title: '地址', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        { field: 'Remark', title: '備註', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
      ],
      rules: {
        Name: [{ required: true }],
        PersonalId: [{
          validator: (params) => {
            if (!params.itemValue) return;
            const sex: boolean | undefined = [0, 1].includes(params.data.Sex) ? params.data.Sex : undefined;
            const birthDate: Date | undefined = params.data.BirthDate || undefined;
            return CloudFun.utils.validator.validatePersonalId(params.itemValue, sex, birthDate);
          }
        }],
        Sex: [{
          validator: params => params.$form.validateField('PersonalId')
        }],
        BirthDate: [{
          validator: params => params.$form.validateField('PersonalId')
        }],
        MobilePhone: [{
          validator: (params) => {
            if (!params.data.MobilePhone && !params.data.Email) return new Error('行動電話或Email至少填寫一項');
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          validator: (params) => {
            if (!params.data.MobilePhone && !params.data.Email) return new Error('行動電話或Email至少填寫一項');
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
      },
    };

    return {
      grid,
      gridOptions,
      formOptions,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  },
});
